import { t } from 'ttag';
import { b64toBlob } from 'utils.js';

const isSubstring = (needle, haystack) => haystack.toString().toLowerCase().includes(needle.toLowerCase());

export const formatCarObject = car => ({
    ...(car.car_data || car.car_spec),
    ...car,
    ...car.car_data?.id && { car_data_id: car.car_data?.id },
    ...car.car_spec?.id && { car_spec_id: car.car_spec?.id },
    clean_acceptable_level: [
        car.car_data?.is_clean || car.car_spec?.is_clean,
        car.car_data?.is_qualified || car.car_spec?.is_qualified,
    ],
});

export const searchFields = (items, searchText, fields) => {
    // console.log('searchFields', { items, searchText, fields });
    if(!searchText) return items; // No searchText = always return all items, right?

    const result = [...items].filter(item => {
        const searchable = fields.map(field => item[field]);
        const searchTerms = searchText.split(' ');

        return searchTerms.every(searchTerm => searchable.some(field => field && isSubstring(searchTerm, field)));
    });

    return result;
};

export const sortVehicles = (vehicles, sort, order, groupBy) => {
    const sortedVehicles = [...vehicles].sort((a, b) => {
        let compA = a[sort];
        let compB = b[sort];

        if(sort === 'labels') { [compA, compB] = findCompValues(a.label_ids, b.label_ids, 0); }

        const isAString = typeof compA === 'string';
        const isBString = typeof compB === 'string';

        let areEqual = (compA === compB);
        if(isAString) areEqual = !compA.localeCompare(compB, undefined, { sensitivity: 'base' });
        if(isBString) areEqual = !compB.localeCompare(compA, undefined, { sensitivity: 'base' });

        if(!areEqual) {
            if(isAString) return order * (compB ? compA.localeCompare(compB, undefined, { sensitivity: 'base' }) : 1);
            if(isBString) return order * (compA ? compB.localeCompare(compA, undefined, { sensitivity: 'base' }) : -1);


            return order * ((compA > compB) ? 1 : -1);
        }

        if(areEqual && a.id !== undefined && b.id !== undefined) {
            compA = a.id;
            compB = b.id;
        }

        return order * ((compA > compB) ? 1 : -1);
    });

    // console.log('sortVehicles', { vehicles, sort, order, groupBy, sortedVehicles });

    if(groupBy) {
        const groupedVehicles = sortedVehicles.reduce((vehicleClasses, car) => {
            if(!vehicleClasses[car[groupBy]]) vehicleClasses[car[groupBy]] = [];
            vehicleClasses[car[groupBy]].push(car);
            return vehicleClasses;
        }, {});

        return Object.keys(groupedVehicles).map(k => [groupedVehicles[k]].flat()).flat();
    } else {
        return sortedVehicles;
    }
};

const findCompValues = (a, b, i) => {
    if(!a[i] || !b[i]) { return [0, 0]; }
    if(a[i] !== b[i]) { return [a[i], b[i]]; }
    if(a[i] === b[i]) { return findCompValues(a, b, i + 1); }

    return [0, 0];
};

export const applyFilters = (vehicles, search, sort, columns, groupBy) => {
    const columnFields = columns.map(column => column.field);

    // console.log('applyFilters', { vehicles, search, sort, columns, groupBy });

    if(sort) {
        const sortedColumn = columns.find(col => col.field === sort);
        const order = sortedColumn ? sortedColumn.order : 1; // Default value of 1 🤷?

        return (
            sortVehicles(
                searchFields(vehicles, search, columnFields),
                sort,
                order,
                groupBy,
            )
        );
    }
    return searchFields(vehicles, search, columnFields);
};

export const updateColumnSorts = (columns, oldSort, newSort) => {
    const columnIndex = columns.findIndex(col => col.field === newSort);
    let columnNewOrder = columns[columnIndex].order;

    if(oldSort === newSort) { columnNewOrder = (columnNewOrder === -1 ? 1 : -1); }

    const newColumns = Object.assign([...columns], {
        [columnIndex]: {
            label: columns[columnIndex].label,
            field: newSort,
            className: columns[columnIndex].className,
            order: columnNewOrder,
        },
    });

    return { newColumns, columnNewOrder };
};

export const toggleColumnField = (columns, oldSort, field) => {
    let newSort = oldSort;
    let newColumns = columns;
    if(columns.map(c => c.field).includes(field)) {
        // Remove column and handle possibly disappearing sort
        newColumns = columns.filter(c => c.field !== field);
        if(oldSort === field) {
            newSort = newColumns[0]?.field || null;
        }
    } else {
        // Add column
        const newColumn = COLUMNS()[field];
        if(newColumn) {
            if(field === 'plate') {
                // If plate is selected, add it to columns as the first one
                newColumns = [{ ...newColumn, order: 1 }, ...columns];
            } else {
                newColumns = [...columns, { ...newColumn, order: 1 }];
            }
        }
    }
    localStorage.setItem('vehicleColumns', newColumns.map(column => column.field));
    return { newSort, newColumns };
};

/*
    When backend adds/modifies/removes column(s), remember to:
        1. Add it to the CATEGORIES array below
        2. Create translations for it (in all languages we use)
        3. ???
*/

export const CATEGORIES = () => [
    {
        label: t`FLEETS_COLUMN_EMISSIONS`,
        id: 'emissions',
        columns: [
            {
                label: t`FLEETS_COLUMN_NEDC_EMISSIONS`,
                field: 'nedc_emissions',
                className: 'column-nedc',
            },
            {
                label: t`FLEETS_COLUMN_WLTP_EMISSIONS`,
                field: 'wltp_emissions',
                className: 'column-wltp',
            },
            {
                label: t`FLEETS_COLUMN_AAE_EMISSIONS`,
                field: 'aae_emissions',
                className: 'column-aae',
            },
            {
                label: {
                    title: t`FLEETS_COLUMN_CLEAN_ACCEPTABLE_LEVEL`,
                    subColumns: [
                        t`CLEAN`,
                        t`ACCEPTABILITY`,
                    ],
                },
                field: 'clean_acceptable_level',
                className: 'column-clean-acceptable',
                sortable: false,
            },
            {
                label: t`FLEETS_COLUMN_EURO_CLASSIFICATION`,
                field: 'euro_emission_standard',
                className: 'column-euro-emission-standard',
            },
            {
                label: t`FLEETS_COLUMN_CVW_INDEX`,
                field: 'vehicle_index',
            },
        ],
    },
    {
        label: t`FLEETS_COLUMN_CATEGORY_DATE`,
        id: 'date',
        columns: [
            {
                label: t`FLEETS_COLUMN_CREATED`,
                field: 'created_at',
            },
            {
                label: t`FLEETS_COLUMN_MODIFIED`,
                field: 'updated_at',
            },
            {
                label: t`FLEETS_COLUMN_COMMISSIONING`,
                field: 'commissioning_date',
            },
            {
                label: t`FLEETS_COLUMN_REGISTRATION_FIRST`,
                field: 'first_registration_date',
            },
            {
                label: t`FLEETS_COLUMN_NTI_END`,
                field: 'next_tech_inspection_end',
            },
            {
                label: t`FLEETS_COLUMN_NTI_START`,
                field: 'next_tech_inspection_start',
            },
            {
                label: t`FLEETS_COLUMN_INSPECTION_LAST`,
                field: 'last_tech_inspection',
            },
            {
                label: t`FLEETS_COLUMN_TRAFI_COMMISSION`,
                field: 'trafi_commissioning_date',
            },
            {
                label: t`FLEETS_COLUMN_EXTERNAL`,
                field: 'updated_at_external',
            },
            {
                label: t`FLEETS_COLUMN_ALCO_INSPECTION_NEXT`,
                field: 'next_alcolock_inspection',
            },
            {
                label: t`FLEETS_COLUMN_TACHO_INSPECTION_NEXT`,
                field: 'next_tachograph_inspection',
            },
        ],
    },
    {
        label: t`FLEETS_COLUMN_CATEGORY_ID`,
        id: 'ids',
        columns: [
            {
                label: t`FLEETS_COLUMN_REGISTRATION`,
                field: 'registration_id',
            },
            {
                label: t`FLEETS_COLUMN_APPRAISAL`,
                field: 'appraisal_id',
            },
            {
                label: t`FLEETS_COLUMN_ORGANIZATION_ID`,
                field: 'organization',
            },
            {
                label: t`FLEETS_COLUMN_ORGANIZATION_NAME`,
                field: 'organization_name',
            },
            {
                label: t`FLEETS_COLUMN_COMPANY_ID`,
                field: 'company',
            },
            {
                label: t`FLEETS_COLUMN_COMPANY_NAME`,
                field: 'company_name',
            },
            {
                label: t`FLEETS_COLUMN_USER`,
                field: 'user',
            },
            {
                label: t`FLEETS_COLUMN_TECH_QUERY`,
                field: 'num_tech_queries',
            },
            {
                label: t`FLEETS_COLUMN_HISTORY_QUERY`,
                field: 'num_history_queries',
            },
            {
                label: t`FLEETS_COLUMN_AT`,
                field: 'at_code',
            },
            {
                label: t`FLEETS_COLUMN_VEHICLE_ID`,
                field: 'plate',
                className: 'column-plate',
            },
        ],
    },
    {
        label: t`FLEETS_COLUMN_CATEGORY_HARDWARE`,
        id: 'hardware',
        columns: [
            {
                label: t`FLEETS_COLUMN_NAME`,
                field: 'name',
            },
            {
                label: t`FLEETS_COLUMN_MAKE`,
                field: 'make',
                className: 'column-make',
            },
            {
                label: t`FLEETS_COLUMN_MODEL`,
                field: 'model',
                className: 'column-model',
            },
            {
                label: t`FLEETS_COLUMN_TYPE`,
                field: 'type',
            },
            {
                label: t`FLEETS_COLUMN_MODEL_YEAR`,
                field: 'model_year',
            },
            {
                label: t`FLEETS_COLUMN_VEHICLE_CLASS`,
                field: 'vehicle_class',
            },
            {
                label: t`FLEETS_COLUMN_CPVS`,
                field: 'cpvs',
            },
            {
                label: t`FLEETS_COLUMN_DRIVE_TYPE`,
                field: 'drive_type',
            },
            {
                label: t`FLEETS_COLUMN_BODY_TYPE`,
                field: 'body_type',
            },
            {
                label: t`FLEETS_COLUMN_TRANSMISSION_TYPE`,
                field: 'transmission_type',
            },
            {
                label: t`FLEETS_COLUMN_ENGINE_VOL`,
                field: 'engine_volume',
            },
            {
                label: t`FLEETS_COLUMN_POWER`,
                field: 'max_power',
            },
            {
                label: t`FLEETS_COLUMN_FUEL`,
                field: 'fuel_type',
                className: 'column-fuel-type',
            },
            {
                label: t`FLEETS_COLUMN_MILEAGE`,
                field: 'mileage',
            },
            {
                label: t`FLEETS_COLUMN_COLOR`,
                field: 'base_color',
            },
            {
                label: t`FLEETS_COLUMN_ALCO`,
                field: 'alcolock',
            },
            {
                label: t`FLEETS_COLUMN_TACHO`,
                field: 'tachograph',
            },
            {
                label: t`FLEETS_COLUMN_LOAD_CAPACITY`,
                field: 'load_capacity',
            },
            {
                label: t`FLEETS_COLUMN_ADDITIONAL`,
                field: 'limiting_terms',
            },
        ],
    },
    /* {
        label: t`FLEETS_COLUMN_CATEGORY_PRICE`,
        id: 'price',
        columns: [
            {
                label: t`FLEETS_COLUMN_MSRP`,
                field: 'msrp_price'
            },
            {
                label: t`FLEETS_COLUMN_UNIT`,
                field: 'unit_price'
            },
            {
                label: t`FLEETS_COLUMN_GTX_PRICE`,
                field: 'gtx_price'
            },
            {
                label: t`FLEETS_COLUMN_GTX_PRICE_RELIABILITY`,
                field: 'gtx_price_reliability'
            },
            {
                label: t`FLEETS_COLUMN_GTX_PRICE_NET`,
                field: 'gtx_net_price'
            }
        ]
    } */
];

export const COLUMNS = () => CATEGORIES()
    .map(category => category.columns)
    .flat()
    .reduce((obj, col) => Object.assign(obj, { [col.field]: col }), {});

export const generateFleetName = fleets => {
    // Generate the first available "Fleet #N" string where N is an integer that isn't already in use
    let name = `${t`FLEET`} #1`;

    for(let i = 1; fleets.find(fleet => fleet.name === `${t`FLEET`} #${i}`);) {
        name = `${t`FLEET`} #${++i}`;
    }

    return name;
};

// TEMP. Will probably come from backend in the future
export const COLOURS = [
    null,
    'var(--theme-dark-accent)',
    'var(--brand-gray-mid)',
    'var(--file-purple)',
    'var(--file-blue)',
    'var(--file-sky-blue)',
    'var(--file-orange)',
    'var(--file-yellow)',
    'var(--file-red)',
];


export const downloadFile = emissions_file => {
    const blob = b64toBlob(emissions_file.content_base64, emissions_file.content_type);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('target', '_self');
    link.setAttribute('download', emissions_file.name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    link.remove();
};

export const DATA_SOURCES = () => ({
    vedia: t`VEDIA`,
    netwheels_appraisal: t`NETWHEELS_APPRAISAL`,
    netwheels_specification: t`NETWHEELS_SPECIFICATION`,
    traficom_opendata: t`TRAFICOM_OPENDATA`,
    eea_opendata: t`EEA_OPENDATA`,
    teoalida: t`TEOALIDA`,
    lipasto: t`LIPASTO`,
    manual: t`MANUAL`,
});

export const alwaysEditableFields = ['cpvs'];
